import apiRequest from './libs/apiRequest';

export interface ICreateProject {
    items_name: string;
    description: string;
}

export interface IUpdateProject {
  id: string;
  items_name: string;
  description: string;
}

// 项目列表
export const getAppItems = (): Promise<IResponse.AppItems.IGetAppItems> => {
  return apiRequest.get({
    url: '/getAppItems',
  });
};

// 创建项目
export const createAppItems = (data: ICreateProject): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: '/createAppItemsByMutex',
    data,
  });
};

// 删除项目
export const deleteAppItems = (id: string): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: '/deleteAppItems',
    data: { id },
  });
};

// 更新项目
export const updateAppItems = (data: IUpdateProject): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: '/updateAppItems',
    data,
  });
};

// 获取项目详情
export const getAppItemsDetail = (id: string): Promise<IResponse.AppItems.IGetAppItemsDetail> => {
  return apiRequest.get({
    url: '/getAppItemsDetail',
    params: { id },
  });
};

// 设置短链接
export const setShortUrl = (id: string, shortUrl: string): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: '/setShortUrl',
    data: { id, short_url: shortUrl },
  });
};
