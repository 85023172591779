import apiRequest from "./libs/apiRequest";

export interface ICreateAppVersion {
  items_id: string;
  CFBundleIdentifier: string;
  CFBundleVersion: string;
  icon: string;
  requisite: number;
  src: string;
  type: string;
  versions_number: string;
  description: string | null;
  App_Store: string | null;
  ExpirationDate: string;
  userGroupId: string | null;
}

export interface IUpdateVersion {
  CFBundleIdentifier: string;
  CFBundleVersion: string;
  description: string;
  icon: string;
  id: string;
  requisite: number;
  src: string;
  type: string;
  versions_number: string;
  App_Store: string | null;
  ExpirationDate: string;
  userGroupId: string | null;
}

export interface IGetVersionDownloadCount {
  items_id: string;
  start_time?: string;
  end_time?: string;
}

export interface IDownloadCount {
  versionId: string;
  itemId: string;
}

// 创建版本
export const createAppItems = (data: ICreateAppVersion): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: "/createVersions",
    data,
  });
};

// 获取最新版本数据
export const upgrade = (id: string): Promise<IResponse.Version.IUpgrade> => {
  return apiRequest.get({
    url: "/upgrade",
    params: { id },
  });
};

// 版本列表
export const getVersions = (id: string): Promise<IResponse.Version.IVersionList> => {
  return apiRequest.get({
    url: "/getVersions",
    params: { id },
  });
};

// 删除版本
export const deleteVersions = (id: string, itemsId: string, fileName: string): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: "/deleteVersions",
    data: { id, items_id: itemsId, fileName },
  });
};

// 更新版本
export const updateVersions = (data: IUpdateVersion): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: "/updateVersions",
    data,
  });
};

// 获取版本详情
export const getVersionDetail = (id: string): Promise<IResponse.Version.IVersionDetail> => {
  return apiRequest.get({
    url: "/getVersionDetail",
    params: { id },
  });
};

// 根据项目名获取最新版本
export const getVersionByName = (name: string): Promise<IResponse.Version.IVersionDetail> => {
  return apiRequest.get({
    url: "/versionByName",
    params: { name },
  });
};

// 获取Plist文件
export const getPlistFile = (id: string): Promise<File> => {
  return apiRequest.get({
    url: "/getAppPlist/" + id,
  });
};

// 获取版本下载次数
export const getVersionDownloadCount = (data: IGetVersionDownloadCount): Promise<IResponse.Version.IGetVersionDownloadCountList> => {
  return apiRequest.get({
    url: "/getVersionDownloadCount",
    params: data,
  });
};

// 获取设备信息
export const getDeviceList = (versionId: string): Promise<IResponse.Version.IGetDeviceList> => {
  return apiRequest.get({
    url: "/getDeviceList",
    params: { version_id: versionId },
  });
};

// 统计版本下载次数
export const downloadCount = (data: IDownloadCount): Promise<IResponse.IResponseMessage> => {
  return apiRequest.get({
    url: "/downloadCount",
    params: {
      version_id: data.versionId,
      item_id: data.itemId,
    },
  });
};
